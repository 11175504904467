<template>
  <v-container
    id="container"
    fluid
  >
    <v-row
      class="text-subtitle-1 mt-8 mt-md-16 pt-md-4 ml-6"
      justify="start"
      align="center"
    >
      <v-icon color="blue">
        mdi-chevron-left
      </v-icon>
      <router-link
        id="sign-up-link"
        to="/login"
        class="text-decoration-none font-weight-bold fs-14 ml-4 mb-0"
      >
        {{ $t("backToSignIn") }}
      </router-link>
    </v-row>
    <v-row class="d-flex align-center mt-16">
      <v-col
        offset="1"
        offset-md="2"
        cols="10"
        md="8"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            role="loginForm"
            @submit.prevent="handleSubmit(sendLink)"
          >
            <v-row
              class="text-h4 font-weight-bold mb-8"
              justify="center"
            >
              {{ $t("forgotPasswordHeader") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("emailLabel") }}
              </v-label>
              <v-text-field
                id="email"
                v-model="email"
                type="email"
                :rules="emailValidation"
                filled
                :placeholder="$t('email')"
              />
            </v-row>

            <v-row class="my-8">
              <v-btn
                block
                type="submit"
                :disabled="isLoading"
                color="primary"
              >
                {{ $t("sendEmail") }}
              </v-btn>
            </v-row>
            <v-row
              class="text-subtitle-1"
              justify="center"
            >
              {{ $t("doNotHaveAccount") }}
              <router-link
                id="sign-up-link"
                to="/signup"
                class="text-decoration-none font-weight-bold fs-14 ml-6"
              >
                {{ $t("signUpTo") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from '@/services/api/auth';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      emailValidation:[v => !!v || this.$t('emailRequired'), v => /.+@.+\..+/.test(v) || this.$t('validEmail')]
    };
  },
  methods: {
    sendLink() {
      this.isLoading = true;
      const authService = makeAuthService(this.$api);
      authService.forgotPassword({ email: this.email }).then(
        response => {
          if (response.status === 200) {
            showSuccessToast(this.$swal, 'passwordResetEmailSent');
            this.isLoading = false;
            this.$router.push({
              path: '/confirmEmail',
              query: {
                email: this.email
              }
            });
          }
        },
        error => {
          this.isLoading = false;
          showErrorToast(this.$swal, 'passwordResetError', { error: error.response.data.error });
        }
      );
    },
  },
};
</script>
